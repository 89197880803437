import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Image, Box, Text, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "80px 0 80px 0",
	"sm-padding": "60px 0 60px 0"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"width": "50%",
			"display": "flex",
			"flex-direction": "column",
			"justify-content": "space-between",
			"md-width": "100%",
			"padding": "0px 0px 0px 0px",
			"lg-padding": "0px 30px 0px 0px",
			"md-padding": "0px 0px 0px 0px",
			"md-margin": "0px 0px 30px 0px",
			"align-self": "center"
		}
	},
	"image": {
		"kind": "Image",
		"props": {
			"src": "https://stardustwhispers.com/img/6.jpg",
			"margin": "0px 30px 20px 30px"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"width": "50%",
			"display": "flex",
			"flex-direction": "column",
			"md-width": "100%",
			"align-self": "center"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 40px 0px",
			"color": "--dark",
			"font": "--headline2",
			"md-margin": "0px 0px 30px 0px",
			"children": "Ziyaretinizi Rezerve Edin"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 40px 0px",
			"color": "--darkL2",
			"font": "--base",
			"children": "Precision Eurorepar farkını yaşamaya hazır mısınız? Randevunuzu planlamak için bugün bize ulaşın ve size araç bakımı için neden tercih edilen seçenek olduğumuzu gösterelim. Güvenli sürün, akıllı sürün, Precision Eurorepar'ü seçin."
		}
	}
};

const Cta = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
		<Box {...override("box")}>
			<Image {...override("image")} />
		</Box>
		<Box {...override("box1")}>
			<Text {...override("text")} />
			<Text {...override("text1")} />
		</Box>
		{children}
	</Section>;
};

Object.assign(Cta, { ...Section,
	defaultProps,
	overrides
});
export default Cta;